import React from "react";
import { FaYoutube, FaTiktok } from "react-icons/fa";
import { GiMailedFist } from "react-icons/gi";
import { GiSwordsEmblem } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import { GiAxeSword } from "react-icons/gi";
import './bellumUrsis.css';  // 

const BellumUrsisPage = () => {
  return (
    <div className="page-container">
      {/* Logo */}
      <img 
        src="/bellumUrsis.png" 
        alt="Logo" 
        className="page-logo"
      />
      
      {/* Titre de la page */}
      <h1 className="page-title"><GiSwordsEmblem /> BELLUM URSIS BEHOURD CLUB <GiMailedFist /></h1>
      <h2 className="page-title">Rejoignez-nous sur nos réseaux !</h2>

      {/* Description de la page */}
      <p className="page-description">Des vidéos et bien plus sur :</p>
      
      {/* Boutons vers YouTube et TikTok */}
      <div className="page-buttons">
        <a 
          href="https://www.youtube.com/channel/UC-9DKTrjee1nDRJehDPKuKw" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="youtube"
        >
          <FaYoutube size={24} /> YouTube
        </a>
        
        <a 
          href="https://www.tiktok.com/@bellum_ursis" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="tiktok"
        >
          <FaTiktok size={24} /> TikTok
        </a>
     
      </div>
      <p className="mailTo"><a href="mailto:bellumursisteam@gmail.com"><MdEmail /> Nous contacter <GiAxeSword /></a></p>
    </div>
  );
};

export default BellumUrsisPage;
