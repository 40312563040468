import React from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Sidebar from '../src/components/sidebar';
import FormComponent from '../src/components/formComponent';
import HomePage from '../src/components/homePage';
import { Provider } from 'react-redux';
import store from './store';
import RegisterForm from './components/registerForm';
import SigninScreen from './components/signInScreen';
import ProfilePage from './components/profilePage';
import UserAvatar from './components/userAvatar';
import E2EPage from './components/e2ePage';
import PaymentPage from './components/PaymentPage';
import SubscriptionPage from './components/SubscriptionPage';
import LicensesPage from './components/LicencesPages';
import SubscriptionScreen from './components/subscriptionScreen';
import PrivateRoute from './components/privateRoute';
import UnitTestsPage from './components/UnitTestsPage';
import TestCasePage from './components/testCasesPage';
import LegalsPage from './components/legalsPage';
import ContactPage from './components/ContactPage';
import JsonFormatterPage from './components/jsonFormatterPage';
import MimifierPage from './components/mimifierPage';
import TextComparePage from './components/textComparePage';
import SqlPerfectorPage from './components/SqlPerfectorPage';
import DashboardPage from './components/dashboardPage';
import XmlParserPage from './components/xmlValidatorPage';
import XmlValidatorPage from './components/xmlValidatorPage';
import CoursesListPage from './components/CoursesListPage';
import AgileTest from './components/AgileTest';
import AgileTestAdvanced from './components/AgileTestAdvanced';
import TDDCourse from './components/TDDPage';
import SecretPage from './components/SecretPage';
import Base64Tool from './components/Base64Page';
import SqlGenPage from './components/SqlGenPage';
import DynamoDBPage from './components/DynamoDBPage';
import ApiToolsheet from './components/ApiToolsheetPage';
import LegalsPageBreakTimeBuddy from './components/legalsPageBreakTimeBuddy';
import BellumUrsisPage from './components/BellumUrsisPage';


const App = () => {


  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <header className="headerDiv">
            <Link to="/home">
              <img
                className="fit-picture"
                src="../ia-qa_logoKawaiiGreenLogo.png"
                alt="IA-QA LOGO"
              />
            </Link>
            <UserAvatar />
          </header>
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <Routes>
            <Route path="/api" element={<PrivateRoute><FormComponent /></PrivateRoute>} />
            <Route path="/unit" element={<PrivateRoute><UnitTestsPage /></PrivateRoute>} />
            <Route path="/testCases" element={<PrivateRoute><TestCasePage /></PrivateRoute>} />
            <Route path="/jsonFormat" element={<PrivateRoute><JsonFormatterPage /></PrivateRoute>} />
            <Route path="/sqlPerfector" element={<PrivateRoute><SqlPerfectorPage /></PrivateRoute>} />
            <Route path="/e2e" element={<PrivateRoute><E2EPage /></PrivateRoute>} />
            <Route path="/dashboardPage" element={<DashboardPage />} />

            <Route path="/legalspagebreaktimebuddy" element={<LegalsPageBreakTimeBuddy></LegalsPageBreakTimeBuddy>}></Route>
            <Route path="/secretPage" element={<SecretPage></SecretPage>}></Route>

            {/*COURSES ROUTES*/}
            <Route path="/agileTester" element={<AgileTest />} />
            <Route path="/apiToolsheet" element={<ApiToolsheet />} />
            <Route path="/agileTesterAdvanced" element={<AgileTestAdvanced />} />
            <Route path="/tddCourse" element={<TDDCourse />} />
            {/*COURSES ROUTES END*/}

            <Route path="/courseList" element={<CoursesListPage />} />
            <Route path="/registerForm" element={<RegisterForm />} />
            <Route path="/login" element={<SigninScreen />} />
            <Route path="/profile" element={<ProfilePage />} />

            <Route path="/legals" element={<LegalsPage />} />
            <Route path="/testCases" element={<TestCasePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/contactUs" element={<ContactPage />} />
            <Route path="/xmlParser" element={<XmlValidatorPage />} />
            <Route path="/DynamoDb" element={<DynamoDBPage />} />
            <Route path="/SqlGenPage" element={<SqlGenPage />} />
            <Route path="/Base64Tool" element={<Base64Tool />} />
            <Route path="/comparePage" element={<TextComparePage />} />
            <Route path="/mimifierTool" element={<MimifierPage />} />
            <Route exact path="/subscriptionsScreen" element={<SubscriptionScreen />} />
            <Route exact path="/subscriptions" element={<SubscriptionPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/licensesReport" element={<LicensesPage />} />

             {/* Route pour BellumUrsisPage */}
          <Route path="/bellumUrsis" element={<BellumUrsisPage />} />


          </Routes>
        </BrowserRouter></Provider>

    </div>
  );
};

export default App;
